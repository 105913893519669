import { FC, useEffect, useMemo } from "react";
import Layout from "components/Layout";

import { fetchAsyncGetActivityReports } from "ducks/activity/slice";
import Activity from "components/Activities/Activity";
import Loader from "components/Loader";
import { useActivities } from "hooks/activitiesHooks";
import { PAGES } from "common/PAGES";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Shadcn/Button";
import { BsPlusCircle } from "react-icons/bs";
import { POST_STATUS } from "common/constants";

const Activities: FC = () => {
  const { dispatch, isLoading, pageName, breadcrumbs, userInfo, activityList } = useActivities();
  const navigate = useNavigate();
  // useEffect
  useEffect(() => {
    dispatch(fetchAsyncGetActivityReports());
  }, [dispatch]);

  const visibleActivityList = useMemo(() => {
    return activityList.filter(r => userInfo.isAdmin === true || r.status !== POST_STATUS.DRAFT);
  }, [activityList, userInfo.isAdmin]);

  const firstActivity = useMemo(() => {
    return activityList.filter(r => userInfo.isAdmin === true || r.status !== POST_STATUS.DRAFT)[0];
  }, [activityList, userInfo.isAdmin]);
  
  return (
    <Layout pageName={pageName} breadcrumbs={breadcrumbs}>
      <></>
      {isLoading ? (
        <Loader />
      ) : (

        <div>
          <div className="flex justify-center h-full p-2">
            <div>
              {userInfo.isAdmin && (
                <Button className="mb-5" onClick={() => navigate(PAGES.ACTIVITY_REPORT_ADD.PATH)}>
                  <BsPlusCircle className="mr-1" />
                  Add Post
                </Button>
              )}
              
              <div className="lg:w-[950px] md:w-[600px] sm:w-[550px]">
                <div className="w-full mb-2">
                  <Activity report={firstActivity} key={`report-${firstActivity.partitionKey}`}></Activity>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                  {visibleActivityList.filter((_, i) => i !== 0).map(r => (
                      <Activity report={r} key={`report-${r.partitionKey}`}></Activity>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Activities;
