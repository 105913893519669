import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "aws-amplify";

import { RootState } from "app/store";
import { sortSkill } from "common/common";
import { API_NAME, API_PATHS } from "common/constants";
import { initialState } from "ducks/employee/initialState";
import {
  ConfirmSearchForm,
  Employee,
  EmployeeDetail,
  EmployeeState,
} from "ducks/employee/type";
import { BadgeStyle } from "components/Profiles/Badge";
import { Skill } from "common/types";

/**
 * 全社員一覧検索
 * @param args none
 **/
export const fetchAsyncSearchEmployees = createAsyncThunk(
  "employee/searchEmployees",
  async (
    args: undefined | {
      hobbyFreeSpace: string;
      skill: Skill | null;
      stateId: number | null;
      fullName: string;
      pageNum: number;
    },
    thunkAPI
  ) => {
    const params = {
      body: {
        hobbyFreeSpace: args?.hobbyFreeSpace ?? "",
        skill: args?.skill ?? null,
        stateId: args?.stateId ?? null,
        fullName: args?.fullName ?? "",
        pageNum: args?.pageNum ?? 1,
      },
    };
    try {
      return await API.post(API_NAME, API_PATHS.EMPLOYEES, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 社員詳細取得
 * @param partitionKey 社員パーティションキー
 **/
export const fetchAsyncGetEmployeeDetail = createAsyncThunk(
  "employee/getEmployeeDetail",
  async (partitionKey: string, thunkAPI) => {
    const params = {
      queryStringParameters: {
        partitionKey,
      },
    };
    try {
      return await API.get(API_NAME, API_PATHS.EMPLOYEE_DETAIL, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * スキルシート取得用の署名付きURLを取得
 * @param fileName
 * @returns pesignedUrl 署名付きURL
 */
export const getSkillSheetUrl = async (fileName: string) => {
  const params = {
    queryStringParameters: {
      fileName,
    },
  };

  const res = await API.get(API_NAME, API_PATHS.SKILLSHEET, params);
  return res.url;
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setIsCategoryChanged(state: EmployeeState, action: PayloadAction<boolean>) {
      return {
        ...state,
        isCategoryChanged: action.payload,
      };
    },
    setIsLocked(state: EmployeeState, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLocked: action.payload,
      };
    },
    setHobbyFreeSpace(state: EmployeeState, action: PayloadAction<string>) {
      return {
        ...state,
        searchForm: { ...state.searchForm, hobbyFreeSpace: action.payload },
      };
    },
    setSelectedSkill(
      state: EmployeeState,
      action: PayloadAction<Skill | null>
    ) {
      return {
        ...state,
        searchForm: { ...state.searchForm, selectedSkill: action.payload },
      };
    },
    setStateId(state: EmployeeState, action: PayloadAction<number | null>) {
      return {
        ...state,
        searchForm: { ...state.searchForm, stateId: action.payload },
      };
    },
    setFullName(state: EmployeeState, action: PayloadAction<string>) {
      return {
        ...state,
        searchForm: { ...state.searchForm, fullName: action.payload },
      };
    },
    setErrorMessage(
      state: EmployeeState,
      action: PayloadAction<string | null>
    ) {
      return {
        ...state,
        searchForm: { ...state.searchForm, errorMessage: action.payload },
      };
    },
    setNowPage(state: EmployeeState, action: PayloadAction<number>) {
      return {
        ...state,
        nowPage: action.payload,
      };
    },
    setConfirmSearchForm(
      state: EmployeeState,
      action: PayloadAction<ConfirmSearchForm | undefined>
    ) {
      return {
        ...state,
        confirmSearchForm: action.payload ?? {
          hobbyFreeSpace: "",
          selectedSkill: null,
          stateId: null,
          fullName: "",
        },
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<EmployeeState>) => {
    builder
      .addCase(
        fetchAsyncGetEmployeeDetail.fulfilled,
        (state: EmployeeState, action: PayloadAction<any>) => {
          const employeeDetail: EmployeeDetail = {
            ...action.payload,
            skills: action.payload.skills.sort(sortSkill),
          };
          return {
            ...state,
            employeeDetail,
            isDetailLoading: false,
          };
        }
      )
      .addCase(
        fetchAsyncGetEmployeeDetail.rejected,
        (state: EmployeeState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncGetEmployeeDetail.pending,
        (state: EmployeeState, action: PayloadAction<any>) => {
          return {
            ...state,
            isDetailLoading: true,
          };
        }
      )
      .addCase(
        fetchAsyncSearchEmployees.fulfilled,
        (state: EmployeeState, action: PayloadAction<any>) => {
          // 入社1ヶ月以内: NEW
          const newComers: Employee[] = action.payload.newCreatedEmployees.map(
            (newCreatedEmployee: Employee) => {
              return { ...newCreatedEmployee, badgeStyle: BadgeStyle.New };
            }
          );
          
          const otherEmployees: Employee[] = action.payload.normalEmployees.map(
            (normalEmployee: Employee) => {
              return { ...normalEmployee, badgeStyle: BadgeStyle.None };
            }
          );

          state.isLoading = false;
          state.pageTotal = action.payload.pageTotal;
          state.employees = [
            ...newComers,
            ...otherEmployees,
          ];

          if (
            action.payload.newCreatedEmployees.length === 0 &&
            action.payload.normalEmployees.length === 0
          ) {
            state.searchForm.errorMessage =
              "検索に一致する社員が見つかりません";
            state.refreshFlg = true;
          }
          return state;
        }
      )
      .addCase(
        fetchAsyncSearchEmployees.rejected,
        (state: EmployeeState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncSearchEmployees.pending,
        (state: EmployeeState, action: PayloadAction<any>) => {
          return {
            ...state,
            isLoading: true,
          };
        }
      );
  },
});

export const {
  setIsCategoryChanged,
  setIsLocked,
  setHobbyFreeSpace,
  setSelectedSkill,
  setStateId,
  setFullName,
  setErrorMessage,
  setNowPage,
  setConfirmSearchForm,
} = employeeSlice.actions;
export const selectIsCategoryChanged = (state: RootState) => state.employee.isCategoryChanged;
export const selectIsLocked = (state: RootState) => state.employee.isLocked;
export const selectIsLoading = (state: RootState) => state.employee.isLoading;
export const selectPageTotal = (state: RootState) => state.employee.pageTotal;
export const selectEmployees = (state: RootState) =>
  state.employee.employees;
export const selectIsDetailLoading = (state: RootState) =>
  state.employee.isDetailLoading;
export const selectEmployeeDetail = (state: RootState) =>
  state.employee.employeeDetail;
export const selectSearchForm = (state: RootState) => state.employee.searchForm;
export const selectConfirmSearchForm = (state: RootState) =>
  state.employee.confirmSearchForm;
export const selectNowPage = (state: RootState) => state.employee.nowPage;
export const selectRefreshFlg = (state: RootState) => state.employee.refreshFlg;
export default employeeSlice.reducer;
