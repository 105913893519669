import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { Command } from "./Shadcn/Command";
import { useSelector } from "react-redux";
import { selectConfirmSearchForm } from "ducks/employee/slice";
import { useSearchArea } from "hooks/profiles/searchAreaHooks";
import { fetchAsyncGetSkills } from "ducks/profile/slice";
import SearchItems from "./SearchItems";
import { Input } from "./Shadcn/Input";
import { useDebounce } from 'use-debounce';
import { useProfile } from "hooks/profileHooks";
import { ConfirmSearchForm } from "ducks/employee/type";
import { useSearchParams } from "react-router-dom";
import { getSearchCategoryByValue } from "common/common";
import { SEARCH_CATEGORY } from "common/constants";

/**
 * 検索入力ボックス
 * @returns
 */
const SearchInput: FC = () => {

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  // query parmeter
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category")
  const word = searchParams.get("word")

  // useHooks
  const {
    dispatch,
    clickSearch,
    searchItemOptions,
    isCategoryChanged,
  } = useSearchArea();
  const {
    employees,
  } = useProfile();

  // スキル取得
  useEffect(() => {
    dispatch(fetchAsyncGetSkills(""));
  }, [dispatch]);

  // 特定の状態でのキー操作制御
  const inputRef = useRef<HTMLInputElement>(null!);
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (!input) return;
    if (e.key === "Escape") {
      input.blur();
      return
    }
    // HACK: 下記の時にエラーが発生するため、押せないように制御する
    if (!open || !options.length) {
      if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
        // カーソルキー(上下)の入力を無効化
        e.preventDefault();
        return
      }
    }
    // 直接検索入力
    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
      searchParams.set("category", category ?? "1");
      searchParams.set("word", inputValue);
      setSearchParams(searchParams);
    }
  }, [inputRef, open, options]);

  // 間引き検索入力
  const [debouncedValue] = useDebounce(inputValue, 300);
  useEffect(() => {
    if (
      !isCategoryChanged && // カテゴリ変更時に動作が競合しないように
      (
        getSearchCategoryByValue(category) === SEARCH_CATEGORY.NAME ||
        getSearchCategoryByValue(category) === SEARCH_CATEGORY.OTHER
      )
    ) {
      searchParams.set("category", category ?? "1");
      searchParams.set("word", debouncedValue);
      setSearchParams(searchParams);
    }
  }, [debouncedValue, category]);

  // 検索実行
  useEffect(() => {
    setInputValue(word ?? "");
    clickSearch();
  }, [word]);

  // 入力制御
  const form = useSelector(selectConfirmSearchForm);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((!employees.length || isConfirmSearchFormEmpty(form)) && !open) {
      setOpen(true)
    }
    setInputValue(e.target.value);
  };
  const isConfirmSearchFormEmpty = (form: ConfirmSearchForm) => {
    return form.fullName === "" &&
      form.selectedSkill === null &&
      form.stateId === null &&
      form.hobbyFreeSpace === "";
  };

  // option設定
  useEffect(() => {
    setOptions(searchItemOptions(inputValue));
  }, [category, inputValue]);

  return (
    <Command
      shouldFilter={true}
      onKeyDown={handleKeyDown}
      onBlur={() => setOpen(false)}
      className="h-10 py-0 border border-l-0 border-input rounded-l-none overflow-visible">
      <Input
        value={inputValue ?? ""}
        className="h-10 px-3 rounded-l-none focus-visible:ring-0 ring-0 border-none shadow-none focus-visible:ring-offset-0"
        onFocus={() => setOpen(true)}
        onChange={handleOnChange}
        ref={inputRef}
      />
      {open && options.length !== 0 && (
        <SearchItems
          options={options}
          setOpen={setOpen}
        ></SearchItems>
      )}
    </Command>
  );
}

export default memo(SearchInput);