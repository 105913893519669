import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { API_NAME, API_PATHS } from "common/constants";
import { RootState } from "app/store";
import { initialState } from "ducks/activity/initialState";
import { ActivityReportState, ActivityReport } from "ducks/activity/type";
import dayjs from "dayjs";

/**
 * 活動報告一覧取得
 * @param args none
 **/
export const fetchAsyncGetActivityReports = createAsyncThunk(
  "activityReports/getactivityReports",
  async (args, thunkAPI) => {
    try {
      return await API.get(API_NAME, API_PATHS.ACTIVITY_REPORT, {});
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const activityReportSlice = createSlice({
  name: "activityReport",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ActivityReportState>) => {
    builder
      .addCase(
        fetchAsyncGetActivityReports.fulfilled,
        (state: ActivityReportState, action: PayloadAction<any>) => {
          // ソートロジック（現状は投稿日の降順）
          const activityList: ActivityReport[] = action.payload.activityReports;
          activityList.sort((a: ActivityReport, b: ActivityReport) => {
            return dayjs(b.createdAt).diff(dayjs(a.createdAt));
          });

          return {
            ...state,
            isLoading: false,
            activityList: activityList,
            viewHistory: action.payload.viewHistory,
          };
        }
      )
      .addCase(
        fetchAsyncGetActivityReports.rejected,
        (state: ActivityReportState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncGetActivityReports.pending,
        (state: ActivityReportState, action: PayloadAction<any>) => {
          return {
            ...state,
            isLoading: true,
          };
        }
      );
  },
});

export const selectIsLoading = (state: RootState) =>
  state.activityReport.isLoading;
export const selectActivityList = (state: RootState) =>
  state.activityReport.activityList;
export const selectViewHistory = (state: RootState) =>
  state.activityReport.viewHistory;
export default activityReportSlice.reducer;
