import { FC, memo, MouseEvent, useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useSelector } from "react-redux";

import { PAGES } from "common/PAGES";
import { DEFAULT_USER_ICON, JOBS, LOCAL_STORAGE_KEYS } from "common/constants";
import ProfileDetailModal from "components/Profiles/ProfileDetailModal";
import { Avatar, AvatarFallback, AvatarImage } from "components/Shadcn/Avatar";
import { Button } from "components/Shadcn/Button";
import { Card, CardContent } from "components/Shadcn/Card";
import { selectIsAdmin } from "ducks/auth/slice";
import { useNavigate } from "react-router-dom";
import KokonaviBadge from "./Badge";

type Props = {
  id: string;
  firstName: string;
  lastName: string;
  picture: string;
  jobId: number;
  deleteFlg: number;
  badgeStyle: number;
};

/**
 * 社員情報
 * @param Props
 * @returns
 */
const Profile: FC<Props> = ({
  id,
  firstName,
  lastName,
  picture,
  jobId,
  deleteFlg,
  badgeStyle,
}) => {
  const navigate = useNavigate();
  const isAdmin: boolean = useSelector(selectIsAdmin);

  const [isDetailModal, setIsDetailModal] = useState<boolean>(false);
  const [positionName, setPositionName] = useState<string>("");

  const clickEditProfile = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    localStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE_EDIT_KEY, id);
    navigate(PAGES.PROFILE_EDIT.PATH);
  };

  const openDetailModal = () => {
    setIsDetailModal(true);
  };
  const closeDetailModal = () => {
    setIsDetailModal(false);
  };

  useEffect(() => {
    let index = 0;
    if (jobId < JOBS.length) {
      index = jobId;
    }
    setPositionName(JOBS[index]);
  }, [jobId]);

  return (
    <div>
      <button
        className="text-left w-full"
        onClick={openDetailModal}
      >
        <Card className="relative overflow-hidden transition-shadow hover:bg-accent">
          <CardContent className="p-4 flex items-center">
            {deleteFlg === 1 && <div className="absolute inset-0 z-10 bg-gray-400 opacity-50"></div>}
            <KokonaviBadge badgeStyle={badgeStyle} />
            <Avatar className="h-20 w-20 mr-4">
              <AvatarImage src={picture} alt="thumbnail" />
              <AvatarFallback><img src={DEFAULT_USER_ICON} alt="defaultThumbnail" /></AvatarFallback>
            </Avatar>
            <div className="flex-grow break-all">
              <p className="text-lg font-medium">{lastName}&nbsp;{firstName}</p>
              <p className="text-sm text-muted-foreground">{positionName}</p>
            </div>
            {isAdmin && (
              <Button
                variant="admin"
                className="px-3 self-end"
                onClick={clickEditProfile}
              >
                編集
                <span className="inline-block">
                  <AiOutlineEdit />
                </span>
              </Button>
            )}
          </CardContent>
        </Card>
      </button>
      <ProfileDetailModal
        isOpen={isDetailModal}
        closeModal={closeDetailModal}
        id={id}
      />
    </div>
  );
};

export default memo(Profile);
