import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import {
  fetchAsyncUploadImg,
  fetchAsyncPutActivityReport,
  setTitle,
  setEyeCatchImg,
  setTexts,
  setHtmlText,
  selectEyeCatchImg,
  selectTitle,
  selectTexts,
  selectHtmlText,
  selectContributorId,
  selectDraftReportList,
  setDraftReportList,
} from "ducks/activityAdd/slice";
import { ActivityReportType, RowType } from "ducks/activityAdd/type";
import uuid from "react-uuid";
import { TypeButton, TypeValues } from "common/types";
import { marked } from "marked";
import { ACTIVITY_BUTTONS, POST_STATUS } from "common/constants";
import { selectUserInfo } from "ducks/auth/slice";
import { UserInfo } from "ducks/auth/type";
import { ChangeEvent, Dispatch, RefObject, SetStateAction } from "react";

export const useActivityAdd = () => {
  /* redux */
  const dispatch = useDispatch<AppDispatch>();
  const userInfo: UserInfo = useSelector(selectUserInfo);
  const texts = useSelector(selectTexts);
  const htmlText = useSelector(selectHtmlText);
  const eyeCatchImg = useSelector(selectEyeCatchImg);
  const title = useSelector(selectTitle);
  const contributorId = useSelector(selectContributorId);
  const draftReportList = useSelector(selectDraftReportList);

  /* utils */
  const editMdAndHtml = () => {
    let mdAll = "";
    const newTexts = texts.map((row: RowType, index: number) => {
      let mdText = row.text;
      switch (row.type) {
        // 1:h1
        case 1:
          mdText = `# ${row.text}`;
          break;
        // 2:h2
        case 2:
          mdText = `## ${row.text}`;
          break;
        // 3:h3
        case 3:
          mdText = `### ${row.text}`;
          break;
        // 4:h4
        case 4:
          mdText = `#### ${row.text}`;
          break;
        // 5:list
        case 5:
          mdText = `- ${row.text}`;
          break;
        // 6:pic
        case 6:
          break;
        // 7:link
        case 7:
          break;
        // 8:under
        case 8:
          mdText = `<hr>`;
          break;
        default:
          if (
            row.text === "" &&
            texts[index - 1] &&
            texts[index - 1].text === ""
          ) {
            mdText = `<p class="break"><br></p>  \n`;
          }
          break;
      }

      if (mdAll === "") {
        if (row.type === 5 || row.type === 6 || row.type === 8) {
          mdAll = mdAll + "  \n" + mdText + "  \n";
        } else {
          mdAll = mdAll + mdText;
        }
      } else {
        if (row.type === 5 || row.type === 6 || row.type === 8) {
          mdAll = mdAll + "  \n  \n" + mdText + "  \n";
        } else {
          mdAll = mdAll + "  \n" + mdText;
        }
      }

      return { ...row, mdText: mdText };
    });

    return { mdAll: mdAll, htmlText: marked.parse(mdAll), newTexts: newTexts };
  };

  /* onClick */
  const onClickEyeCatch = (ref: RefObject<HTMLInputElement>) => {
    ref.current?.click();
  };

  const onClickPost = async (
    draftPartitionKey: string,
    setEyeCatchFile: Dispatch<SetStateAction<File | null>>,
    setDraftPartitionKey: Dispatch<SetStateAction<string>>
  ) => {
    // 編集処理
    const { htmlText, newTexts } = { ...editMdAndHtml() };

    // APIのCALL処理
    const newPartitionKey = uuid();

    await dispatch(
      fetchAsyncPutActivityReport({
        partitionKey:
          draftPartitionKey === "" ? newPartitionKey : draftPartitionKey,
        activityReportAddState: {
          eyeCatchImg: eyeCatchImg,
          title: title,
          texts: newTexts,
          htmlText: htmlText,
          contributorId: contributorId,
        },
        postStatus: POST_STATUS.PUBLISH, // 投稿中
        exist: draftPartitionKey !== "",
      })
    );

    // 後処理
    dispatch(setEyeCatchImg(""));
    dispatch(setTitle(""));
    dispatch(setTexts([]));
    dispatch(setHtmlText(""));
    if (draftPartitionKey !== "") {
      const newDraftReportList: ActivityReportType[] = draftReportList.filter(
        (draftReport: ActivityReportType) => {
          return draftReport.partitionKey !== draftPartitionKey;
        }
      );
      dispatch(setDraftReportList(newDraftReportList));
    }
    setEyeCatchFile(null);
    setDraftPartitionKey("");
    alert("投稿が完了しました。");
  };

  const onClickSave = async (
    draftPartitionKey: string,
    setDraftPartitionKey: Dispatch<SetStateAction<string>>
  ) => {
    // 編集処理
    const { mdAll, newTexts } = { ...editMdAndHtml() };

    const newPartitionKey = uuid();

    await dispatch(
      fetchAsyncPutActivityReport({
        partitionKey:
          draftPartitionKey === "" ? newPartitionKey : draftPartitionKey,
        activityReportAddState: {
          eyeCatchImg: eyeCatchImg,
          title: title,
          texts: newTexts,
          htmlText: marked.parse(mdAll),
          contributorId: contributorId,
        },
        postStatus: POST_STATUS.DRAFT, // 下書き
        exist: draftPartitionKey !== "",
      })
    );

    if (draftPartitionKey !== "") {
      const newDraftReportList: ActivityReportType[] = draftReportList.map(
        (draftReport: ActivityReportType) => {
          if (draftReport.partitionKey === draftPartitionKey) {
            return {
              partitionKey: draftPartitionKey,
              eyeCatchImg: eyeCatchImg,
              title: title,
              texts: texts,
            };
          } else {
            return draftReport;
          }
        }
      );
      dispatch(setDraftReportList(newDraftReportList));
    } else {
      const newDraftReportList: ActivityReportType[] = [
        ...draftReportList,
        {
          partitionKey: newPartitionKey,
          eyeCatchImg: eyeCatchImg,
          title: title,
          texts: texts,
        },
      ];
      dispatch(setDraftReportList(newDraftReportList));
    }

    setDraftPartitionKey(
      draftPartitionKey === "" ? newPartitionKey : draftPartitionKey
    );
    alert("下書き保存が完了しました。");
  };

  const onClickP = (index: number) => {
    const newTexts: RowType[] = texts.map((row: RowType, num: number) => {
      if (index === num) {
        return { ...row, editFlg: true };
      } else {
        return row;
      }
    });
    dispatch(setTexts(newTexts));
  };

  const onClickTypeButton = (
    type: TypeValues,
    index: number,
    setOpenButtonPop: Dispatch<SetStateAction<boolean>>
  ) => {
    const newTexts: RowType[] = texts.map((row: RowType, num: number) => {
      if (index === num) {
        let text = row.text;
        switch (type) {
          // 1:h1
          case 1:
            break;
          // 2:h2
          case 2:
            break;
          // 3:h3
          case 3:
            break;
          // 4:h4
          case 4:
            break;
          // 5:list
          case 5:
            break;
          // 6:pic
          case 6:
            break;
          // 7:link
          case 7:
            text = '[リンクテキスト](URL "タイトル")';
            break;
          // 8:under
          case 8:
            text = "- - - -";
            break;
          default:
            break;
        }
        return { ...row, text: text, type: row.type === type ? 0 : type };
      } else {
        return row;
      }
    });
    dispatch(setTexts(newTexts));
    setOpenButtonPop(false);
  };

  const onClickHiddenInput = (index: number) => {
    const newTexts: RowType[] = texts.map((row: RowType, num: number) => {
      if (index === num) {
        return { ...row, editFlg: true };
      } else {
        return row;
      }
    });
    dispatch(setTexts(newTexts));
  };

  const onClickPreview = () => {
    const { htmlText, newTexts } = { ...editMdAndHtml() };
    dispatch(setTexts(newTexts));
    dispatch(setHtmlText(htmlText));
  };

  const onClickRowImageType = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const onClickDraftTitle = (
    draftReport: ActivityReportType,
    setDraftPartitionKey: Dispatch<SetStateAction<string>>,
    setDraftModalFlg: Dispatch<SetStateAction<boolean>>
  ) => {
    dispatch(setTitle(draftReport.title));
    dispatch(setEyeCatchImg(draftReport.eyeCatchImg));
    dispatch(setTexts(draftReport.texts));
    setDraftPartitionKey(draftReport.partitionKey);
    setDraftModalFlg(false);
  };

  /* onKey(Enter) */
  const onKeyEnterRow = (index: number) => {
    let beforeTexts: RowType[] = texts.slice(0, index + 1);
    beforeTexts[index] = {
      ...beforeTexts[index],
      editFlg: false,
    };
    const afterTexts: RowType[] = texts.slice(index + 1);
    const newTexts: RowType[] = [
      ...beforeTexts,
      {
        text: "",
        mdText: "",
        editFlg: true,
        type: 0,
      },
      ...afterTexts,
    ];
    dispatch(setTexts(newTexts));
  };

  const onKeyBackSpaceRow = (index: number) => {
    let beforeTexts: RowType[] = texts.slice(0, index);
    beforeTexts[index - 1] = {
      ...beforeTexts[index - 1],
      editFlg: true,
    };
    const afterTexts: RowType[] = texts.slice(index + 1);
    const newTexts: RowType[] = [...beforeTexts, ...afterTexts];
    dispatch(setTexts(newTexts));
  };

  /* onBlur */
  const onBlurInput = (index: number) => {
    const newTexts: RowType[] = texts.map((row: RowType, num: number) => {
      if (index === num) {
        return { ...row, editFlg: false };
      } else {
        return row;
      }
    });
    dispatch(setTexts(newTexts));
  };

  /* onChange */
  const onChangeEyeCatch = async (
    e: ChangeEvent<HTMLInputElement>,
    setEyeCatchFile: Dispatch<SetStateAction<File | null>>
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      const id = uuid();
      const uploadFileName = `${id}.webp`;
      const blob = file.slice(0, file.size, file.type);
      const renamedFile = new File([blob], uploadFileName, {
        type: file.type,
      });
      setEyeCatchFile(renamedFile);
      await dispatch(
        fetchAsyncUploadImg({
          uploadFileName: uploadFileName,
          file: renamedFile,
        })
      );
      dispatch(
        setEyeCatchImg(
          `https://${process.env.REACT_APP_ACTIVITY_REPORT_BUCKET}.s3.ap-northeast-1.amazonaws.com/${uploadFileName}`
        )
      );
    }
  };

  const onChangeInput = (newValue: string, index: number) => {
    const newTexts: RowType[] = texts.map((row: RowType, num: number) => {
      if (index === num) {
        return { ...row, text: newValue };
      } else {
        return row;
      }
    });
    dispatch(setTexts(newTexts));
  };

  const onChangeRowImage = async (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
    setOpenButtonPop: Dispatch<SetStateAction<boolean>>
  ) => {
    if (e.target.files && e.target.files[0]) {
      // file読込
      const file: File = e.target.files[0];
      const id = uuid();
      const uploadFileName = `${id}.webp`;
      const blob = file.slice(0, file.size, file.type);
      const renamedFile = new File([blob], uploadFileName, {
        type: file.type,
      });

      // s3へのアップロード
      await dispatch(
        fetchAsyncUploadImg({
          uploadFileName: uploadFileName,
          file: renamedFile,
        })
      );

      // ステータス更新
      const newTexts: RowType[] = texts.map((row: RowType, num: number) => {
        if (index === num) {
          return {
            ...row,
            text: `![](https://${process.env.REACT_APP_ACTIVITY_REPORT_BUCKET}.s3.ap-northeast-1.amazonaws.com/${uploadFileName})`,
            type: 6,
          };
        } else {
          return row;
        }
      });
      dispatch(setTexts(newTexts));
      setOpenButtonPop(false);
    }
  };

  /* onDrop & onPaste */
  const dropAndPasteFunction = async (
    dataTransfer: DataTransfer,
    index: number
  ) => {
    const file: File | null = dataTransfer.files.item(0);
    const id = uuid();
    const uploadFileName = `${id}.webp`;
    if (file !== null) {
      const blob = file.slice(0, file.size, file.type);
      const renamedFile = new File([blob], uploadFileName, {
        type: file.type,
      });
      await dispatch(
        fetchAsyncUploadImg({
          uploadFileName: uploadFileName,
          file: renamedFile,
        })
      );

      const newTexts: RowType[] = texts.map((row: RowType, num: number) => {
        if (index === num) {
          return {
            ...row,
            text: `![](https://${process.env.REACT_APP_ACTIVITY_REPORT_BUCKET}.s3.ap-northeast-1.amazonaws.com/${uploadFileName})`,
            type: 6,
          };
        } else {
          return row;
        }
      });

      dispatch(setTexts(newTexts));
    }
  };

  const onPasteRow = async (
    index: number,
    selectionStart: number,
    selectionEnd: number
  ) => {
    // paste文字を取得
    const readText = await navigator.clipboard.readText();
    const pasteTexts = readText.split(/\n/);
    const newPasteTexts = pasteTexts.map((pasteText: string) => {
      return pasteText.replace("\r", "");
    });

    // フラグ判定
    const pasteMultiFlg = newPasteTexts.length > 1;

    // 編集
    const beforeTexts: RowType[] = texts.slice(0, index);
    let targetText: RowType[] = texts.slice(index, index + 1);
    const afterStr = targetText[0].text.substring(selectionEnd);

    targetText[0] = {
      ...targetText[0],
      text: pasteMultiFlg
        ? targetText[0].text.substring(0, selectionStart) + newPasteTexts[0]
        : targetText[0].text.substring(0, selectionStart) +
          newPasteTexts[0] +
          afterStr,
      editFlg: false,
    };
    const addTexts: RowType[] = [];
    for (let i = 1; i < newPasteTexts.length; i++) {
      addTexts.push({
        text:
          pasteMultiFlg && i === newPasteTexts.length - 1
            ? newPasteTexts[i] + afterStr
            : newPasteTexts[i],
        mdText: "",
        editFlg: i === newPasteTexts.length - 1,
        type: 0,
      });
    }

    const afterTexts: RowType[] = texts.slice(index + 1);

    const newTexts: RowType[] = [
      ...beforeTexts,
      ...targetText,
      ...addTexts,
      ...afterTexts,
    ];

    dispatch(setTexts(newTexts));
  };

  /* judge */
  const judgePostDisabled = () => {
    return eyeCatchImg === "" || title === "" || contributorId === ""
      ? true
      : false;
  };

  const judgeDraftDisabled = () => {
    return draftReportList.length === 0;
  };

  const judgeInputStyle = (row: RowType) => {
    let fontSize = undefined;
    let fontWeight = undefined;
    let color = undefined;
    let marginLeft = undefined;
    switch (row.type) {
      // 1:h1
      case 1:
        fontSize = "2em";
        fontWeight = "bolder";
        break;
      // 2:h2
      case 2:
        fontSize = "1.5em";
        fontWeight = "bolder";
        break;
      // 3:h3
      case 3:
        fontSize = "1.17em";
        fontWeight = "bolder";
        break;
      // 4:h4
      case 4:
        fontSize = "1em";
        fontWeight = "bolder";
        break;
      // 5:list
      case 5:
        marginLeft = 28;
        break;
      // 6:pic
      case 6:
        break;
      // 7:link
      case 7:
        color = "#0C66E4";
        break;
      // 8:under
      case 8:
        break;
      default:
        break;
    }
    return {
      fontSize: fontSize,
      fontWeight: fontWeight,
      color: color,
      width: "80%",
      minHeight: 24,
      marginLeft: marginLeft,
      borderBottom: "solid",
      borderWidth: 1,
      borderColor: "#808080",
    };
  };

  const judgePStyle = (row: RowType) => {
    let fontSize = undefined;
    let fontWeight = undefined;
    let color = undefined;
    let marginLeft = undefined;
    switch (row.type) {
      // 1:h1
      case 1:
        fontSize = "2em";
        fontWeight = "bolder";
        break;
      // 2:h2
      case 2:
        fontSize = "1.5em";
        fontWeight = "bolder";
        break;
      // 3:h3
      case 3:
        fontSize = "1.17em";
        fontWeight = "bolder";
        break;
      // 4:h4
      case 4:
        fontSize = "1em";
        fontWeight = "bolder";
        break;
      // 5:list
      case 5:
        marginLeft = 14;
        break;
      // 6:pic
      case 6:
        break;
      // 7:link
      case 7:
        color = "#0C66E4";
        break;
      // 8:under
      case 8:
        break;
      default:
        break;
    }
    return {
      zIndex: 0,
      fontSize: fontSize,
      fontWeight: fontWeight,
      color: color,
      width: "80%",
      minHeight: 24,
      marginLeft: marginLeft,
      borderBottom: "solid",
      borderWidth: 1,
      borderColor: "#808080",
    };
  };

  const judgeTypeIconJudge = (index: number) => {
    const row: RowType = texts[index];
    let icon = "";

    ACTIVITY_BUTTONS.forEach((activityButton: TypeButton) => {
      if (activityButton.value === row.type) {
        icon = activityButton.icon;
      }
    });
    return icon;
  };

  return {
    /* redux */
    dispatch,
    userInfo,
    texts,
    htmlText,
    eyeCatchImg,
    title,
    draftReportList,

    /* onClick */
    onClickEyeCatch,
    onClickPost,
    onClickSave,
    onClickP,
    onClickTypeButton,
    onClickHiddenInput,
    onClickPreview,
    onClickRowImageType,
    onClickDraftTitle,

    /* onKey(Enter) */
    onKeyEnterRow,
    onKeyBackSpaceRow,

    /* onBlur */
    onBlurInput,

    /* onChange */
    onChangeInput,
    onChangeEyeCatch,
    onChangeRowImage,

    /* onDrop & onPaste */
    dropAndPasteFunction,
    onPasteRow,

    /* judge */
    judgePostDisabled,
    judgeDraftDisabled,
    judgeInputStyle,
    judgePStyle,
    judgeTypeIconJudge,
  };
};
