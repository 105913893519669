import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "app/store";
import { SEARCH_CATEGORY, STATEIDS, SearchCategoryValue } from "common/constants";
import { selectSearchedSkills } from "ducks/profile/slice";
import {
  setHobbyFreeSpace,
  setSelectedSkill,
  setStateId,
  setFullName,
  selectSearchForm,
  setNowPage,
  setConfirmSearchForm,
  selectIsLoading,
  setErrorMessage,
  selectEmployees,
  setIsCategoryChanged,
  selectIsCategoryChanged,
} from "ducks/employee/slice";
import { Skill } from "common/types";
import { fetchAsyncSearchEmployees } from "ducks/employee/slice";
import { ConfirmSearchForm } from "ducks/employee/type";
import { useSearchParams } from "react-router-dom";
import { getSearchCategoryByValue } from "common/common";
import { useEffect } from "react";

/** 社員一覧検索エリアフック */
export const useSearchArea = () => {
  type prefSelect = {
    value: number;
    label: string;
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category")
  const word = searchParams.get("word")
  const searchForm = useSelector(selectSearchForm);
  const {
    hobbyFreeSpace,
    selectedSkill,
    stateId,
    errorMessage,
  } = {
    ...searchForm,
  };

  const dispatch = useDispatch<AppDispatch>();
  const isCategoryChanged: boolean = useSelector(selectIsCategoryChanged);
  const isLoading: boolean = useSelector(selectIsLoading);
  const searchedEmployeeNames: string[] = useSelector(selectEmployees).map(employee => employee.lastName + employee.firstName)
  const searchedSkills: Skill[] = useSelector(selectSearchedSkills);
  const searchedPrefectures: prefSelect[] = STATEIDS.map(
    (stateId: string, index: number) => {
      return { value: index, label: stateId };
    }
  ).filter((temp) => temp.value !== 0);

  const clickSearch = () => {
    dispatch(setErrorMessage(null));
    const param = getSearchForm(word ?? "")
    const inParam = {
      hobbyFreeSpace: param.hobbyFreeSpace,
      skill: param.selectedSkill,
      stateId: param.stateId,
      fullName: param.fullName,
      pageNum: 1,
    };
    dispatch(fetchAsyncSearchEmployees(inParam));
    dispatch(setNowPage(1));
    dispatch(
      setConfirmSearchForm({
        hobbyFreeSpace: param.hobbyFreeSpace,
        selectedSkill: param.selectedSkill,
        stateId: param.stateId,
        fullName: param.fullName,
      })
    );
  };

  const clickBack = () => {
    dispatch(fetchAsyncSearchEmployees());
    dispatch(setConfirmSearchForm());
    dispatch(setNowPage(1));
  };

  const getSearchForm = (input: string): ConfirmSearchForm => {
    
    let form: ConfirmSearchForm = {
      hobbyFreeSpace: "",
      selectedSkill: null,
      stateId: null,
      fullName: "",
    }
    
    if (!input) {
      return form
    }

    switch (getSearchCategoryByValue(category)) {
      case SEARCH_CATEGORY.NAME:
        dispatch(setFullName(input))
        form.fullName = input
        break;
      case SEARCH_CATEGORY.PREFECTURE:
        const prefecture = searchedPrefectures.find(prefecture => prefecture.label === input) ?? { label: "", value: 999 };
        dispatch(setStateId(prefecture.value));
        form.stateId = prefecture.value
        break;
      case SEARCH_CATEGORY.SKILL:
        const skill = searchedSkills.find(skill => skill.skillName === input) ?? { skillName: "", skillType: 0 };
        dispatch(setSelectedSkill(skill));
        form.selectedSkill = skill;
        break;
      case SEARCH_CATEGORY.OTHER:
        dispatch(setHobbyFreeSpace(input));
        form.hobbyFreeSpace = input;
    }
    return form
  }

  const changeSearchCategory = (category: SearchCategoryValue) => {
    setSearchParams({category: String(category.value), word: ""});
    dispatch(setFullName(""));
    dispatch(setStateId(null));
    dispatch(setSelectedSkill(null));
    dispatch(setHobbyFreeSpace(""));
    dispatch(setIsCategoryChanged(true));
  }
  useEffect(() => {
    dispatch(setIsCategoryChanged(false));
  }, [dispatch, isCategoryChanged])

  const skills = searchedSkills.map((s) => s.skillName);
  const prefectures = searchedPrefectures.map((s) => s.label);
  const getBaseItemOptions = () => {
    let options: string[];
    switch (getSearchCategoryByValue(category)) {
      case SEARCH_CATEGORY.PREFECTURE:
        options = prefectures;
        break;
      case SEARCH_CATEGORY.SKILL:
        options = skills;
        break;
      default:
        options = [];
    }
    return options;
  }
  const baseItemOptions = getBaseItemOptions();
  const searchItemOptions = (word: string) => {
    return word ? baseItemOptions.filter(option => option.includes(word)) : baseItemOptions;
  }

  const resetParameters = () => {
    dispatch(setFullName(""));
    dispatch(setStateId(null));
    dispatch(setSelectedSkill(null));
    dispatch(setHobbyFreeSpace(""));
  }

  return {
    dispatch,
    resetParameters,
    searchItemOptions,
    searchedEmployeeNames,
    searchedSkills,
    searchedPrefectures,
    selectedSkill,
    setSelectedSkill,
    hobbyFreeSpace,
    setHobbyFreeSpace,
    stateId,
    setStateId,
    clickSearch,
    clickBack,
    errorMessage,
    changeSearchCategory,
    isCategoryChanged,
    isLoading,
  };
};