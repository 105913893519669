import { TypeButton } from "./types";

/** ココシナロゴファイルパス */
export const KOKOSYNA_LOGO_FILE_NAME = `${process.env.PUBLIC_URL}/kokosyna-logo.svg`;
export const KOKOSYNA_V2_LOGO_FILE_NAME = `${process.env.PUBLIC_URL}/kokosyna-v2-logo.svg`;
export const KOKOSYNA_URL = "https://kokosyna.com/";
export const KOKOSYNA_NAVIGATE_CODE = "healthicons:ui-menu-grid";

/** ココナビロゴファイルパス */
export const LOGO_FILE_NAME = `${process.env.PUBLIC_URL}/kokonavi-blue-logo.svg`;
export const VERTICAL_LOGO_FILE_NAME = `${process.env.PUBLIC_URL}/kokorozashi-logo-vertical.svg`;
/** ココロザシロゴファイルパス */
export const KOKOROZASHI_ICON_FILE_NAME = `${process.env.PUBLIC_URL}/kokorozashi-icon.svg`;
export const KOKOROZASHI_BLUE_ICON_FILE_NAME = `${process.env.PUBLIC_URL}/kokorozashi-blue-icon.png`;
/** デフォルトユーザアイコンのパス */
export const DEFAULT_USER_ICON = `${process.env.PUBLIC_URL}/defaultUserIcon.png`;
/** デフォルト活動報告アイキャッチのパス */
export const DEFAULT_ACTIVITY_EYECATCH = `${process.env.PUBLIC_URL}/default_activity_eyecatch.png`;
/** ポータル共通ボタンローディング */
export const PORTAL_BTN_LOADING = `${process.env.PUBLIC_URL}/portalBtnLoading.svg`;
/** ContactUs送信先メールアドレス */
export const CONTACT_US_MAIL_ADDRES =
  "information-system@kokorozashi-japan.co.jp";
/** slide用 */
export const HOME_SLIDE_ONE = `${process.env.PUBLIC_URL}/home_slide_flag.jpg`;
export const HOME_SLIDE_TWO = `${process.env.PUBLIC_URL}/home_slide_value.jpg`;
export const HOME_SLIDE_THREE = `${process.env.PUBLIC_URL}/home_slide_referral1.jpg`;
export const HOME_SLIDE_FOUR = `${process.env.PUBLIC_URL}/home_slide_referral2.jpg`;
export const HOME_SLIDE_FIVE = `${process.env.PUBLIC_URL}/home_slide1.jpg`;
export const HOME_SLIDE_SIX = `${process.env.PUBLIC_URL}/home_slide2.jpg`;
export const HOME_SLIDE_SEVEN = `${process.env.PUBLIC_URL}/home_slide3.jpg`;
export const HOME_SLIDE_EIGHT = `${process.env.PUBLIC_URL}/home_slide4.jpg`;
export const HOME_SLIDE_NINE = `${process.env.PUBLIC_URL}/home_slide5.jpg`;
export const NEW_SLIDE = {
  HOME_SLIDE_ONE: `${process.env.PUBLIC_URL}/newSlide/slide1.jpg`,
  HOME_SLIDE_TWO: `${process.env.PUBLIC_URL}/newSlide/slide2.jpg`,
  HOME_SLIDE_THREE: `${process.env.PUBLIC_URL}/newSlide/slide3.jpg`,
  HOME_SLIDE_FOUR: `${process.env.PUBLIC_URL}/newSlide/slide4.jpeg`,
  HOME_SLIDE_FIVE: `${process.env.PUBLIC_URL}/newSlide/slide5.jpeg`,
  HOME_SLIDE_SIX: `${process.env.PUBLIC_URL}/newSlide/slide6.png`,
  HOME_SLIDE_SEVEN: `${process.env.PUBLIC_URL}/newSlide/slide7.jpg`,
  HOME_SLIDE_EIGHT: `${process.env.PUBLIC_URL}/newSlide/slide8.jpeg`,
}
/** マネーフォワード勤怠リンク */
export const MF_ATTENDANCE = `https://attendance.moneyforward.com/`;
/** マネーフォワード経費精算リンク */
export const MF_COST = `https://expense.moneyforward.com/`;
/** マネーフォワード給与明細リンク */
export const MF_PAYSLIP = `https://payroll.moneyforward.com/`;
/** マネーフォワード年末調整リンク */
export const MF_ADJUSTMENT = `https://tax-adjustment.moneyforward.com/`;
/** オフィスステーションリンク */
export const OFFICE_STATION = `https://service.officestation.jp/8e557n/user-mypage/auth`;
/** 会社ロゴファイルパス */
export const COMPANY_LOGO_FILE_NAME = `${process.env.PUBLIC_URL}/kokorozashi-logo.png`;
/** ログインボタンのimg */
export const LOGIN_BUTTON_FILE_NAME = `${process.env.PUBLIC_URL}/btn_google_signin_light_normal_web.png`;
/** TOP用_Xリンク */
export const X_URL = "https://twitter.com/kokoro_saiyo";
/** TOP用_wantedlyリンク */
export const WANTEDLY_LOGO = `${process.env.PUBLIC_URL}/wantedly-logo.svg`;
export const WANTEDLY_URL = "https://www.wantedly.com/companies/company_299137";
/** TOP用_コーポレートサイト */
export const CORPORATE_URL = "https://kokorozashi-japan.co.jp/";
/** TOP用_採用サイト */
export const RECRUIT_URL = "https://recruit.kokorozashi-japan.co.jp/";

/** API名 */
export const API_NAME = "APIGatewayAPI";

/** APIパス名 */
export const API_PATHS = {
  USER_INFO: "/userInfo",
  EMPLOYEES: "/employees",
  EMPLOYEE_DETAIL: "/employeeDetail",
  SKILLSHEET: "/skillSheet",
  PRESIGN_FOR_PUT: "/presignForPut",
  SKILLS: "/skills",
  WELFARES: "/welfares",
  WELFARE_DETAIL: "/welfareDetail",
  NOTICES: "/notices",
  WORK_REGULATIONS: "/workRegulations",
  WORK_REGULATION_DELETE: "/workRegulationDelete",
  WORK_REGULATION_DOWNLOAD_GET: "/workRegulationDownloadUrlGet",
  WORK_REGULATION_UPLOAD_GET: "/workRegulationUploadUrlGet",
  ACTIVITY_REPORT_UPLOAD_GET: "/activityReportUploadUrlGet",
  ACTIVITY_REPORT: "/activityReport",
  ACTIVITY_REPORT_DETAIL: "/activityReportDetail",
  ACTIVITY_REPORT_HISTORY: "/activityReportHistory",
  ACTIVITY_REPORT_DRAFT: "/activityReportDraft",
  ACTIVITY_REPORT_UPDATE: "/activityReportUpdate",
  FAQS: "/faqs",
  FAQ_DETAIL: "/faqDetail",
  FAQ_TITLE: "/faqTitle",
};

/** S3プレフィックス名 */
export const S3_PREFIXES = {
  WORK_REGULATION: "就業規則",
  CHILDCARE_LEAVE: "育休・産休について",
  HYGIENE_MANAGEMENT: "衛生管理委員会について（議事録）",
  DEFINED_CONTRIBUTION_PENSION: "確定拠出年金について",
  P_MARK_AND_ISMS: "Pマーク・ISMS関連について",
};

/** 未登録文言 */
export const UNREGISTERED = "(未登録)";

/** 分類(所属)定義 */
export const JOBS = ["その他", "コーポレート", "エンジニア"];

/** 都道府県定義 */
export const STATEIDS = [
  "",
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

/** 年月日フォーマット */
export const DATE_FORMAT = "yyyy/mm/dd";

/** スキルタイプ */
export const SKILL_TYPES = [
  {
    code: 1,
    name: "backend",
  },
  {
    code: 2,
    name: "frontend",
  },
  {
    code: 3,
    name: "infrastructure",
  },
  {
    code: 4,
    name: "ai",
  },
  {
    code: 5,
    name: "qualification",
  },
  {
    code: 99,
    name: "other",
  },
] as const;

/** 検索種別 */
export const SEARCH_CATEGORY = {
  NAME: {
    label: "名前",
    value: 1,
  },
  PREFECTURE: {
    label: "都道府県",
    value: 2,
  },
  SKILL: {
    label: "スキル",
    value: 3,
  },
  OTHER: {
    label: "趣味・フリースペース",
    value: 4,
  },
} as const;
export type SearchCategoryValue = typeof SEARCH_CATEGORY[keyof typeof SEARCH_CATEGORY];

/** 社員詳細インプット最大文字数定義 */
export const PROFILE_ITEM_MAX_LENGTH = {
  LAST_NAME: 20,
  FIRST_NAME: 20,
  LAST_NAME_KANA: 20,
  FIRST_NAME_KANA: 20,
  STAFF_ID: 20,
  BIRTHDAY: 10,
  HIRE_DATE: 10,
  MAIL: 100,
  STATE: 5,
  CITY: 10,
  SKILLS: 20,
  HOBBIES: 2000,
  FREE_SPACE: 2000,
};

/** 社員詳細インプット最小文字数定義 */
export const PROFILE_ITEM_MIN_LENGTH = {
  SKILLS: 1,
  FREE_SPACE: 50,
};

/** 社員詳細インプットキー */
export const PROFILE_INPUT_KEY = {
  LAST_NAME: "lastName",
  FIRST_NAME: "firstName",
  LAST_NAME_KANA: "lastNameKana",
  FIRST_NAME_KANA: "firstNameKana",
  STAFF_ID: "staffId",
  JOB: "job",
  BIRTHDAY: "birthday",
  HIRE_DATE: "hireDate",
  AGE: "age",
  EMAIL: "email",
  STATEID: "stateId",
  CITY: "city",
  SKILLS: "skills",
  HOBBIES: "hobbies",
  FREE_SPACE: "freeSpace",
  DELETE_FLG: "deleteFlg",
};

/** 福利厚生インプット最大文字数定義 */
export const WELFARE_ITEM_MAX_LENGTH = {
  EXPLANATION: 2000,
  APPLICATION: 2000,
  NAME: 50,
};

/** 福利厚生インプットキー */
export const WELFARE_INPUT_KEY = {
  EXPLANATION: "explanation",
  APPLICATION: "application",
  NAME: "name",
};

/** 各種申請リンク */
export const APPLICATION_LINKS = [
  { title: "マネーフォワード勤怠", href: MF_ATTENDANCE, description: "" },
  { title: "マネーフォワード経費精算", href: MF_COST, description: "" },
  { title: "マネーフォワード給与明細", href: MF_PAYSLIP, description: "" },
  { title: "マネーフォワード年末調整", href: MF_ADJUSTMENT, description: "" },
  { title: "オフィスステーション", href: OFFICE_STATION, description: "住所変更や口座変更等の労務手続きはこちら" },
];

export const POST_STATUS = {
  DRAFT: "1",
  PUBLISH: "2"
} as const;
export type PostStatus = typeof POST_STATUS[keyof typeof POST_STATUS];

/** 入力エラーメッセージ */
export const ERR_MSG = {
  EMPTY: "※入力必須項目",
  INCORRECT: "※正しい値を入力してください。",
  DATE_FORMAT: `※${DATE_FORMAT}形式で入力`,
};

/** localStorageキー */
export const LOCAL_STORAGE_KEYS = {
  PROFILE_EDIT_KEY: "profileEditId",
  WELFARE_EDIT_KEY: "welfareEditId",
};

/** localStorageキー */
export const ACTIVITY_BUTTONS: TypeButton[] = [
  { icon: "material-symbols:format-h1-rounded", value: 1 },
  { icon: "material-symbols:format-h2-rounded", value: 2 },
  { icon: "material-symbols:format-h3-rounded", value: 3 },
  { icon: "material-symbols:format-h4-rounded", value: 4 },
  { icon: "ion:list-outline", value: 5 },
  { icon: "ant-design:picture-filled", value: 6 },
  { icon: "ph:link-bold", value: 7 },
  { icon: "pepicons-pop:line-x", value: 8 },
];
