import { FC } from "react";
import { Icon } from "@iconify/react";
import { ActivityReport } from "ducks/activity/type";
import { useActivities } from "hooks/activitiesHooks";
import { PAGES } from "common/PAGES";
import { Button } from "components/Shadcn/Button";
import { useNavigate } from "react-router-dom";
import { Card } from "components/Shadcn/Card";
import { Avatar, AvatarFallback, AvatarImage } from "components/Shadcn/Avatar";
import { DEFAULT_ACTIVITY_EYECATCH, DEFAULT_USER_ICON, POST_STATUS } from "common/constants";
import { AspectRatio } from "components/Shadcn/AspactRatio";

type ActivityProps = {
  report: ActivityReport;
};
export const Activity: FC<ActivityProps> = ({ report }) => {
  const navigate = useNavigate();
  const { userInfo, onClickDetail } =
    useActivities();
  const dispHeaderImage = !!report.eyeCatchImg
  return (
    <Card className="cursor-pointer border-b duration-100 hover:bg-accent" 
      onClick={() => onClickDetail(report.partitionKey)}>
      <div className="flex flex-col">
        {/* アイキャッチ */}
        <AspectRatio ratio={16 / 9} className="rounded-t-lg w-full">
            {dispHeaderImage ? (
              <img
                src={report.eyeCatchImg}
                alt="eye-catch"
                className="object-cover w-full h-full"
              />
            ) : (
              <img
                src={DEFAULT_ACTIVITY_EYECATCH}
                alt="eye-catch"
              />
            )}
        </AspectRatio>

        {/* コンテンツ */}
        <div className="flex flex-col gap-2 p-3 md:gap-3 md:p-3">
          {/* タイトル */}
          <div className="flex items-center min-h-[3rem]">
            <h2 className="line-clamp-2 font-semibold text-base">
              {report.status === POST_STATUS.DRAFT ? `【下書き記事】${report.title}` : report.title}
            </h2>
          </div>

          {/* 投稿者 */}
          <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-5">
            <div className="flex items-center gap-3">
              <Avatar>
                <AvatarImage src={report.contributorImg} alt="avatar" />
                <AvatarFallback><img src={DEFAULT_USER_ICON} alt="avatar"></img></AvatarFallback>
              </Avatar>
              <div className="whitespace-nowrap text-xs lg:text-sm">
                {report.contributorName}
              </div>
            </div>
          </div>

          {/* 投稿日、view数、編集ボタン */}
          <div className="flex items-center gap-3 justify-between">
            <div className="flex flex-row gap-3">
              <div className="flex items-center gap-1">
                <Icon icon="ic:round-access-time" />
                <div className="whitespace-nowrap text-xs text-black/50">
                  {report.createdAt.substring(0, report.createdAt.indexOf(" "))}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <Icon icon="carbon:view" />
                <div className="text-xs text-black/50">
                  <span>{report.viewCount}</span>
                </div>
              </div>
            </div>
            {userInfo.isAdmin && (
              <Button
                variant="admin"
                className="z-10"
                onClick={(e) => {
                  navigate(`${PAGES.ACTIVITY_REPORT_UPDATE.PATH}?reportId=${report.partitionKey}`);
                  e.stopPropagation()
                }}
              >
                Update
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Activity;
