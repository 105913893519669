import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "aws-amplify";
import { RootState } from "app/store";
import {
  ActivityReportAddState,
  ActivityReportPutReq,
  ActivityReportType,
  PutActivityReport,
  RowType,
} from "./type";
import { initialState } from "ducks/activityAdd/initialState";
import { API_NAME, API_PATHS, PostStatus } from "common/constants";

/**
 * 活動報告下書き一覧取得
 * @param args none
 **/
export const fetchAsyncGetActivityReportDrafts = createAsyncThunk(
  "activityReportAdd/getActivityReportDraft",
  async (contributorId: string, thunkAPI) => {
    const getDraftsParams = {
      queryStringParameters: {
        contributorId: contributorId,
      },
    };
    try {
      return await API.get(
        API_NAME,
        API_PATHS.ACTIVITY_REPORT_DRAFT,
        getDraftsParams
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 画像のアップロード
 * @param args none
 **/
export const fetchAsyncUploadImg = createAsyncThunk(
  "activityReport/upLoadImg",
  async (args: { uploadFileName: string; file: File }, thunkAPI) => {
    const presignedGetParams = {
      queryStringParameters: {
        uploadFileName: args.uploadFileName,
      },
    };
    try {
      // 署名付きURL取得
      const presignedUrl: any = await API.post(
        API_NAME,
        API_PATHS.ACTIVITY_REPORT_UPLOAD_GET,
        presignedGetParams
      );

      // ファイルアップロード
      const options = {
        headers: {
          "Content-Type": args.file.type,
        },
      };
      await axios.put(presignedUrl.pdfUrl, args.file, options);

      return;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * 画像の投稿
 * @param activityReportAddState 活動報告stateの設定値
 **/
export const fetchAsyncPutActivityReport = createAsyncThunk(
  "activityReport/putActivityReport",
  async (
    args: {
      partitionKey: string;
      activityReportAddState: ActivityReportPutReq;
      postStatus: PostStatus;
      exist: boolean;
    },
    thunkAPI
  ) => {
    // DB更新パラメータ
    const params: PutActivityReport = {
      body: {
        partitionKey: args.partitionKey,
        eyeCatchImg: args.activityReportAddState.eyeCatchImg,
        title: args.activityReportAddState.title,
        texts: args.activityReportAddState.texts,
        htmlText: args.activityReportAddState.htmlText,
        contributorId: args.activityReportAddState.contributorId,
        postStatus: args.postStatus,
        exist: args.exist,
      },
    };
    try {
      return await API.put(API_NAME, API_PATHS.ACTIVITY_REPORT, params);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const activityReportAddSlice = createSlice({
  name: "activityReportAdd",
  initialState,
  reducers: {
    setEyeCatchImg(
      state: ActivityReportAddState,
      action: PayloadAction<string>
    ) {
      return {
        ...state,
        eyeCatchImg: action.payload,
      };
    },
    setTitle(state: ActivityReportAddState, action: PayloadAction<string>) {
      return {
        ...state,
        title: action.payload,
      };
    },
    setTexts(state: ActivityReportAddState, action: PayloadAction<RowType[]>) {
      return {
        ...state,
        texts: action.payload,
      };
    },
    setHtmlText(state: ActivityReportAddState, action: PayloadAction<string>) {
      return {
        ...state,
        htmlText: action.payload,
      };
    },
    setContributorId(
      state: ActivityReportAddState,
      action: PayloadAction<string>
    ) {
      return {
        ...state,
        contributorId: action.payload,
      };
    },
    setDraftReportList(
      state: ActivityReportAddState,
      action: PayloadAction<ActivityReportType[]>
    ) {
      return {
        ...state,
        draftReportList: action.payload,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ActivityReportAddState>) => {
    builder
      .addCase(
        fetchAsyncGetActivityReportDrafts.fulfilled,
        (state: ActivityReportAddState, action: PayloadAction<any>) => {
          return {
            ...state,
            draftReportList: action.payload.draftReportList,
          };
        }
      )
      .addCase(
        fetchAsyncGetActivityReportDrafts.rejected,
        (state: ActivityReportAddState, action: PayloadAction<any>) => {
          // window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncGetActivityReportDrafts.pending,
        (state: ActivityReportAddState, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncUploadImg.fulfilled,
        (state: ActivityReportAddState, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncUploadImg.rejected,
        (state: ActivityReportAddState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncUploadImg.pending,
        (state: ActivityReportAddState, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncPutActivityReport.fulfilled,
        (state: ActivityReportAddState, action: PayloadAction<any>) => {}
      )
      .addCase(
        fetchAsyncPutActivityReport.rejected,
        (state: ActivityReportAddState, action: PayloadAction<any>) => {
          window.location.href = "/error";
        }
      )
      .addCase(
        fetchAsyncPutActivityReport.pending,
        (state: ActivityReportAddState, action: PayloadAction<any>) => {}
      );
  },
});

export const {
  setEyeCatchImg,
  setTitle,
  setTexts,
  setHtmlText,
  setContributorId,
  setDraftReportList,
} = activityReportAddSlice.actions;

export const selectEyeCatchImg = (state: RootState) =>
  state.activityReportAdd.eyeCatchImg;
export const selectTitle = (state: RootState) => state.activityReportAdd.title;
export const selectTexts = (state: RootState) => state.activityReportAdd.texts;
export const selectHtmlText = (state: RootState) =>
  state.activityReportAdd.htmlText;
export const selectContributorId = (state: RootState) =>
  state.activityReportAdd.contributorId;
export const selectDraftReportList = (state: RootState) =>
  state.activityReportAdd.draftReportList;

export default activityReportAddSlice.reducer;
